import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';

export default (
  ComposedComponent,
  styles,
  mapStateToProps,
  mapDispatchToProps,
) => {
  class BaseContainer extends React.PureComponent {
    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  const wrapped = withSnackbar(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(BaseContainer),
  );

  if (styles) return withStyles(styles)(wrapped);
  return wrapped;
};
