import React from 'react';
import { isSalonzone } from '../utils';

const FontSelector = ({ children }) => {
  return (
    <>
      {isSalonzone ? (
        <style>
          {`
              @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
              
              td,p,div,h1,h2,h3,h4,h5,h6,p,span,label,th,td,li,legend {
                font-family: 'Montserrat' !important;
              }
            `}
        </style>
      ) : null}
      {children}
    </>
  );
};

export default FontSelector;
