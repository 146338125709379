import React from 'react';
import * as _ from 'lodash';

import { Box } from '@material-ui/core';

import BaseHOC from '../components/BaseHOC';
import FontSelector from '../components/FontSelector';

const PolicyPage = ({ pageContext: { policy } }) => {
  return policy ? (
    <FontSelector>
      <Box p={2}>
        <div dangerouslySetInnerHTML={{ __html: policy.body }} />
      </Box>
    </FontSelector>
  ) : null;
};

export default BaseHOC(PolicyPage);
